











import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import SignUpForm from '@/components/SignUpForm.vue';
import SuccessView from '@/components/SuccessView.vue';

@Component({
  components: {
    SuccessView,
    SignUpForm,
  },
})
export default class App extends Vue {
  @Prop() version: string | undefined

  wcVersion = 'large';

  step = 'sign';

  created() {
    if (this.version === 'tile') {
      this.wcVersion = 'small';
    }
  }

  subscribe(status: string) {
    if (status === 'succ') {
      this.step = 'success';
    }
  }
}
