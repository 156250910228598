



























































import {
  Component,
  Prop,
  Vue, Watch,
} from 'vue-property-decorator';
import axios from 'axios';
import { Request } from '@/types/Request';
import EyeCatcher from '@/components/EyeCatcher.vue';

@Component({
  components: { EyeCatcher },
})
export default class SignUpForm extends Vue {
  @Prop() version: string | undefined

  public firstname = '';

  public lastname = '';

  public mail = '';

  public disabled = true;

  // eslint-disable-next-line max-len,no-control-regex
  private mailRegex = new RegExp('(?:[a-zA-Z0-9!#$%&*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')

  public err = false;

  public errMsg = '';

  subStep = 'cover';

  showSubscribeForm(): void {
    this.subStep = 'subscribe';
  }

  subscribe(): void {
    const request: Request = {
      firstname: this.firstname,
      lastname: this.lastname,
      mail: this.mail,
      id: process.env.VUE_APP_NEWSLETTER_ID,
    };

    axios.post(`${process.env.VUE_APP_SIGNUP_SERVICE_BASE_URL}/newsletterSignupServiceRest.php?addContact`, request)
      .then((result) => {
        console.log(result);
        this.$emit('subscribe', 'succ');
      })
      .catch((err) => {
        console.log(err);
        this.errMsg = 'Bitte geben Sie keine Verteiler-Adresse an';
        this.err = true;
      });
  }

  @Watch('mail')
  onMailChange(): void {
    console.log(this.mail.match(this.mailRegex));
    if (this.mail !== '') {
      if (this.mail.match(this.mailRegex)) {
        if (this.mail.startsWith('service') || this.mail.startsWith('marketing') || this.mail.startsWith('support')) {
          this.disabled = true;
          this.errMsg = 'Bitte geben Sie keine Verteiler-Adresse an';
          this.err = true;
        } else {
          this.disabled = false;
          this.err = false;
        }
      } else {
        this.disabled = true;
        this.errMsg = 'Bitte geben Sie eine gültige E-Mail-Adresse an';
        this.err = true;
      }
    }
  }
}
