// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/bigwave.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".nl-signup[data-v-f616651e]{font-family:immo-sans-webfont,HelveticaNeue,Helvetica Neue,Helvetica-Neue,Helvetica,Arial,sans-serif;padding:32px}.large[data-v-f616651e]{background:#303038}.large[data-v-f616651e],.tile[data-v-f616651e]{border-radius:16px}.tile[data-v-f616651e]{height:100%;background:#303038;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat bottom #303038;position:relative}", ""]);
// Exports
module.exports = exports;
