













import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import EyeCatcher from '@/components/EyeCatcher.vue';

@Component({
  components: { EyeCatcher },
})
export default class ErrorView extends Vue {
  @Prop() readonly version: string | undefined
}
