import Vue from 'vue';
import wrap from '@vue/web-component-wrapper';
import App from './App.vue';

Vue.config.productionTip = false;

const CustomElement = wrap(Vue, App);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
window.customElements.define('hoh-signup-form', CustomElement);
